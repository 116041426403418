<template>
  <a-modal
    title="用户角色授权"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">
    <a-spin :spinning="loading">
      <a-checkbox-group class="group-check" @change="onChange" v-model="currentList">
        <a-row>
          <a-col v-for="item in authorizeList" :span="6" :key="item.id">
            <a-checkbox :value="item.roleId" class="check-name">
              {{ item.roleName }}
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </a-spin>
  </a-modal>
</template>

<script>
import { getRoleByUserId, roleAccredit } from '@/api/user-rights/usermanage'

export default {
  name: 'AcUserAuthorize',
  data () {
    return {
      visible: false,
      confirmLoading: false,
      // 授权列表
      authorizeList: [],
      // 选中列表
      currentList: [],
      loading: false,
      userId: null
    }
  },
  methods: {
    /**
     *编辑打开
     */
    edit (record) {
      this.userId = record.userId
      this.handleRoleList(record.userId)
      this.visible = true
    },
    /**
     * 取消并关闭
     */
    handleCancel () {
      this.userId = null
      this.authorizeList = []
      this.currentList = []
      this.visible = false
    },
    /**
     * 提交
     */
    handleSubmit () {
      this.confirmLoading = true
      const role = JSON.parse(JSON.stringify(this.currentList))
          roleAccredit({ userId: this.userId, roleIds: role }).then((res) => {
            this.confirmLoading = false
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.handleCancel()
              this.$emit('ok', role)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
    },
    /**
     * 组变化
     * @param checkedValues
     */
    onChange (checkedValues) {
      console.log('checked = ', checkedValues)
    },
    handleRoleList (id) {
      this.loading = true
      getRoleByUserId({ userId: id }).then(res => {
        if (res.code === 200) {
          if (res.data && res.data['acRoleList'] && Array.isArray(res.data['acRoleList'])) {
            for (let i = 0; i < res.data['acRoleList'].length; i++) {
              // 过滤超级用户
              console.log(res.data['acRoleList'][i].roleKey, '111')
              if (res.data['acRoleList'][i].roleKey === 'admin') {
                res.data['acRoleList'].splice(i, 1)
              }
            }
          }
          this.authorizeList = res.data['acRoleList']
          this.currentList = res.data['roleIds']
        }
      }).catch(e => {
        console.error(e)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "~@/assets/styles/modal";

.group-check{
  width: 100%;
}
.check-name{
  max-width: 188px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  line-height: 1.5;
}
</style>
